<script>
    export let per;
  </script>
  
<div>
  <div class="form-group row">
    <label for="person-name" class="col-sm-2 col-form-label">Nome: </label>
    <div class="col-sm-10">
      <input id="person-name" name="person-name" class='form-control' bind:value={per.name}/>
    </div>
  </div>
  <div class="form-group row">
    <label for="person-email" class="col-sm-2 col-form-label">Email: </label>
    <div class="col-sm-10">
      <input id="person-email" name="person-email" disabled class='form-control' bind:value={per.email}/>
    </div>
  </div>
  <div class="form-group row">
    <label for="person-tel" class="col-sm-2 col-form-label">Telefone: </label>
    <div class="col-sm-10">
      <input id="person-tel" name="person-tel" class='form-control' bind:value={per.telephone}/>
    </div>
  </div>
  <div class="form-group row">
    <label for="person-job" class="col-sm-2 col-form-label">Profissão: </label>
    <div class="col-sm-10">
      <input id="person-job" name="person-job" class='form-control' bind:value={per.jobTitle}/>
    </div>
  </div>
  <div class="form-group row">
    <label for="person-link" class="col-sm-2 col-form-label">Link: </label>
    <div class="col-sm-10">
      <input id="person-link" name="person-link" class='form-control' bind:value={per.url}/>
    </div>
  </div>
</div>