// JSONs de Structured Data
// import {json as howToJSON} from './schemaData/HowTo.js';
// import {json as localBusinessJSON} from './schemaData/LocalBusiness.js';
// import {json as productJSON} from './schemaData/Product.js';
// import {json as recipeJSON} from './schemaData/Recipe.js';
// import {json as specialAnnouncementJSON} from './schemaData/SpecialAnnouncement.js';
import {json as videoObjectJSON} from './schemaData/VideoObject.js';
import { json as newsArticleJSON } from './schemaData/NewsArticle.js';
import { json as faqPageJSON } from './schemaData/FaqPage.js';
import { json as webPageJSON } from './schemaData/children/WebPage.js';
import { json as reviewJSON } from './schemaData/Review.js';
import { json as JobPostingJSON } from './schemaData/JobPosting.js';
// Tipos de Structured Data
// import Event from './schemaEditor/Event.svelte';
// import HowTo from './schemaEditor/HowTo.svelte';
// import LocalBusiness from './schemaEditor/LocalBusiness.svelte';
// import Product from './schemaEditor/Product.svelte';
// import Recipe from './schemaEditor/Recipe.svelte';
// import SpecialAnnouncement from './schemaEditor/SpecialAnnouncement.svelte';
import VideoObject from './schemaEditor/VideoObject.svelte';
import NewsArticle from './schemaEditor/NewsArticle.svelte';
import FaqPage from './schemaEditor/FaqPage.svelte';
import WebPage from './schemaEditor/children/WebPage.svelte';
import Review from './schemaEditor/Review.svelte';
import JobPosting from './schemaEditor/JobPosting.svelte';

export let tipos = [
  //{name: 'Evento', type: 'Event', component: Event, json: eventJSON},
  //{name: 'Passo-a-Passo', type: 'HowTo', component: HowTo, json: howToJSON},
  //{name: 'LocalBusiness', type: 'LocalBusiness', component: LocalBusiness, json: localBusinessJSON},
  //{name: 'Produto', type: 'Product', component: Product, json: productJSON},
  //{name: 'Receita (comida)', type: 'Recipe', component: Recipe, json: recipeJSON},
  //{name: 'Anuncio COVID', type: 'SpecialAnnouncement', component: SpecialAnnouncement, json: specialAnnouncementJSON},
  {name: 'Pagina Web', type: 'WebPage', component: WebPage, json: webPageJSON},
  {name: 'Notícia', type: 'NewsArticle', component: NewsArticle, json: newsArticleJSON},
  {name: 'FAQ', type: 'FAQPage', component: FaqPage, json: faqPageJSON},
  {name: 'JobPosting', type: 'JobPosting', component: JobPosting, json: JobPostingJSON},
  {name: 'Review', type: 'Review', component: Review, json: JSON.parse(JSON.stringify(reviewJSON))},
  {name: 'Video', type: 'VideoObject', component: VideoObject, json: videoObjectJSON}
]

export function tipoPorNome(nome){
  let tipo = tipos.filter((t) => t.type == nome)[0];
  return tipo || tipos[0];
}