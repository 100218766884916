<script>
  export let sd, nameItem;

  import { verifyKeysSD } from '../../../../lib/helper';
  import {json as jsonEmpresa} from '../../../schemaData/children/ReviewDataTypes/EmpresaLocal.js';
  import PostalAddress from '../../../schemaEditor/children/PostalAddress.svelte';
  import optionsGroup from './Data/optgroup';
  import days from './Data/days';

  let daysComercial = false;
  let daySegSexOpen = "";
  let daySegSexClose = "";

  $: { nameItem = `Empresa Local: ${sd.name}`; };
  $: { if (sd['@type'] !== 'FoodEstablishment') delete sd.servesCuisine; };
  
  if(!Object.keys(sd).includes('openingHoursSpecification')) sd = JSON.parse(JSON.stringify(jsonEmpresa));
  verifyKeysSD(jsonEmpresa, sd);

  days.forEach(dDays => {
    sd.openingHoursSpecification.forEach(dSd=>{
      if(dSd.dayOfWeek.includes(dDays.value)){
        dDays.status = true;
        dDays.open = dSd.opens;
        dDays.close = dSd.closes;
      };
    });
  });
  
  const changeSdDays = () => {
    let horarios = [];
    if (daysComercial){
      days.forEach((d, i) => {
        if (i<=4 && daySegSexOpen !== '' && daySegSexClose !== ''){
          let obj = {"@type": "OpeningHoursSpecification","dayOfWeek": [d.value,],"opens": daySegSexOpen,"closes": daySegSexClose}
          horarios.push(obj);
        }else if (d.open !== '' && d.close !== '' && d.status){
          let obj = {"@type": "OpeningHoursSpecification","dayOfWeek": [d.value],"opens": d.open,"closes": d.close}
          horarios.push(obj);
        }
      });
    }else{
      days.forEach(d => {
        let obj = {"@type": "OpeningHoursSpecification","dayOfWeek": [d.value,],"opens": d.open,"closes": d.close};
        if (d.open !== '' && d.close !== '' && d.status)horarios.push(obj);
      });
    }
    sd.openingHoursSpecification = horarios;
  }
  
</script>

<div>
  <div class="form-group row">
    <label for="modelo-empresa-local" class="col-sm-2 col-form-label">Modelo: </label>
    <div class="col-sm-10">
      <select id="modelo-empresa-local" name="modelo-empresa-local" class='form-select' bind:value={sd['@type']}>
        <option value="" disabled selected> - Selecione - </option>
        {#each optionsGroup as group}
          <optgroup label={group.name}>
            {#each group.options as option}
              <option value={option.replace(/ /g, '')}>{option}</option>
            {/each}
          </optgroup>
        {/each}
      </select>
    </div>
  </div>

  <div class="form-group row">
    <label for="nome-da-empresa-local" class="col-sm-2 col-form-label">Nome da empresa: </label>
    <div class="col-sm-10">
      <input id="nome-da-empresa-local" name="nome-da-empresa-local" class='form-control' bind:value={sd.name}/>
    </div>
  </div>

  {#if sd['@type'] === 'FoodEstablishment'}
  <div class="form-group row">
    <label for="tipo-de-comida-local" class="col-sm-2 col-form-label">Tipo de comida: </label>
    <div class="col-sm-10">
      <input id="tipo-de-comida-local" name="tipo-de-comida-local" class='form-control' bind:value={sd.servesCuisine}/>
    </div>
  </div>
  {/if}

  <div class="form-group row">
    <label for="imagem-empresa-local" class="col-sm-2 col-form-label">Imagem: </label>
    <div class="col-sm-10">
      <input id="imagem-empresa-local" name="imagem-empresa-local" class='form-control'  bind:value={sd.image}/>
    </div>
  </div>
  
  <PostalAddress bind:address={sd.address}/>
  <div class="form-group row">
    <label for="geolocalizacao-latitude-EL" class="col-sm-2 col-form-label">Geolocalização - Latitude: </label>
    <div class="col-sm-10">
      <input id="geolocalizacao-latitude-EL" name="geolocalizacao-latitude-EL" class='form-control' bind:value={sd.geo.latitude}/>
    </div>
  </div>
  <div class="form-group row">
    <label for="geolocalizacao-longitudel-EL" class="col-sm-2 col-form-label">Geolocalização - Longitude: </label>
    <div class="col-sm-10">
      <input id="geolocalizacao-longitudel-EL" name="geolocalizacao-longitudel-EL" class='form-control'  bind:value={sd.geo.longitude}/>
    </div>
  </div>
  <div class="form-group row">
    <label for="url-da-empresa-EL" class="col-sm-2 col-form-label">URL da empresa: </label>
    <div class="col-sm-10">
      <input id="url-da-empresa-EL" name="url-da-empresa-EL" class='form-control' bind:value={sd.url}/>
    </div>
  </div>
  <div class="form-group row">
    <label for="id-url-empresa-local" class="col-sm-2 col-form-label">@ID - (URL): </label>
    <div class="col-sm-10">
      <input id="id-url-empresa-local" name="id-url-empresa-local" class='form-control' bind:value={sd.url}/>
    </div>
  </div>
  <div class="form-group row">
    <label for="telefone-empresa-local" class="col-sm-2 col-form-label">Telefone: </label>
    <div class="col-sm-10">
      <input id="telefone-empresa-local" name="telefone-empresa-local" class='form-control' bind:value={sd.telephone}/>
    </div>
  </div>
  <div class="form-group row">
    <label for="preco-medio-empresa-local" class="col-sm-2 col-form-label">Preco medio: </label>
    <div class="col-sm-10">
      <input id="preco-medio-empresa-local" name="preco-medio-empresa-local" class='form-control' bind:value={sd.priceRange}/>
      <small class="spam col-form-label" >Medido por $. Sendo apenas 1 muito barato e 4 muito caro ($, $$, $$$ ou $$$$)</small>
    </div>
  </div>
 
  <div class="form-group row">
    <div class="col-sm-2 col-form-label">Horario de funcionamento: </div>

    <div class="col-sm-10">
      <label for="daysComercial"><input type="checkbox" bind:checked={daysComercial} id="daysComercial" name="daysComercial" value={daysComercial}> Mesmo horário de segunda à sexta?</label>
      {#if daysComercial}
        <div class="form-group row">
          <div class="col-sm-5">
            <label for='open-segSex-EL' class="col-sm-10 col-form-label">Horario de abertura:</label>
            <input class='form-control' type='text' pattern='[0-9]+:[0-9]+' maxlength='5' placeholder="00:00" id='open-segSex-EL' on:keyup={changeSdDays} name='open-segSex-EL' bind:value={daySegSexOpen}/>
          </div>
          <div class="col-sm-5">
            <label for='close-segSex-EL' class="col-sm-10 col-form-label">Horario de fechamento: </label>
            <input class='form-control' type='text' pattern='[0-9]+:[0-9]+' maxlength='5' placeholder="00:00" id='close-segSex-EL' on:keyup={changeSdDays} name='close-segSex-EL' bind:value={daySegSexClose}/>
          </div>
        </div>
        {#each days as day, i}
          {#if i > 4}
            <div class="col-sm">
              <label for="day-checkbox-{day.name}">
                <input type="checkbox" bind:checked={day.status} name="day-checkbox-{day.name}" id="day-checkbox-{day.name}" value={day.value}>{" "+day.name}
              </label>
              {#if day.status}
                <div class="form-group row">
                  <div class="col-sm-5">
                    <label for='open-{day.name}' class="col-sm-10 col-form-label">Horario de abertura:</label>
                    <input class='form-control' type='text' pattern='[0-9]+:[0-9]+' maxlength='5' placeholder="00:00" id='open-{day.name}' on:keyup={changeSdDays} name='open-{day.name}' bind:value={day.open}/>
                  </div>
                  <div class="col-sm-5">
                    <label for='close-{day.name}' class="col-sm-10 col-form-label">Horario de fechamento: </label>
                    <input class='form-control' type='text' pattern='[0-9]+:[0-9]+' maxlength='5' placeholder="00:00" id='close-{day.name}' on:keyup={changeSdDays} name='close-{day.name}' bind:value={day.close}/>
                  </div>
                </div>
              {/if}
            </div>
          {/if}
        {/each}
      {:else}
        {#each days as day}
          <div class="col-sm">
            <label><input type="checkbox" bind:checked={day.status} name="day" value={day.value}>{" "+day.name}</label>
            {#if day.status}
              <div class="form-group row">
                <div class="col-sm-5">
                  <label for='open-{day.name}' class="col-sm-10 col-form-label">Horario de abertura:</label>
                  <input class='form-control' type='text' pattern='[0-9]+:[0-9]+' maxlength='5' placeholder="00:00" id='open-{day.name}' on:keyup={changeSdDays} name='open-{day.name}' bind:value={day.open}/>
                </div>
                <div class="col-sm-5">
                  <label for='close-{day.name}' class="col-sm-10 col-form-label">Horario de fechamento: </label>
                  <input class='form-control' type='text' pattern='[0-9]+:[0-9]+' maxlength='5' placeholder="00:00" id='close-{day.name}' on:keyup={changeSdDays} name='close-{day.name}' bind:value={day.close}/>
                </div>
              </div>
            {/if}
          </div>
        {/each}
      {/if}
    </div>
  </div>
</div>