export let json = {
  "@context": "https://schema.org",
  "@type": "Book",
  "@id": "RealSeguroViagem-asdf-Book",
  "url": "http://example.com/work/the_catcher_in_the_rye",
  "image": "http://example.com/work/the_catcher_in_the_rye.png",
  "name": "The Catcher in the Rye",
  "author": { "@type": "Person", "name": "J.D. Salinger" },
  "bookFormat": "http://schema.org/EBook",
  "inLanguage": "English",
  "isbn": "00000000",
  "sameAs": "https://en.wikipedia.org/wiki/The_Catcher_in_the_Rye"
}