<script>
  import { formatFulltDate, formatDate } from '../../lib/helper';
  import ImageObject from './children/ImageObject.svelte';
  import WebPage from './children/WebPage.svelte';
  import Person from './children/Person.svelte';
  export let sd, nameItem;
  
  $: { nameItem = `${sd.headline}`; };

  let dates = { dateModified: formatDate(new Date()), datePublished: formatDate(new Date()) }
</script>

<div>
  <div class="form-group row">
    <label for="chamada" class="col-sm-2 col-form-label">Chamada: </label>
    <div class="col-sm-10">
      <input id="chamada" name="chamada" class='form-control' bind:value={sd.headline}/>
    </div>
  </div>
  <WebPage bind:sd={sd.mainEntityOfPage}/>
  <hr/>
  <h6>Imagem de capa (Atualiza automático. Faça upload na área "Imagem de Capa"):</h6>
  <ImageObject bind:image={sd.image} desabilitar={true} />
  <hr/>
  <h6>Datas:</h6>
  <div class="form-group row">
    <label for="publicado-em" class="col-sm-2 col-form-label">Publicado em: </label>
    <div class="col-sm-10">
      <input type="datetime-local" value={dates.datePublished} id="publicado-em" name="publicado-em" class='form-control' on:change={(e) => sd.datePublished = formatFulltDate(e.target.value)}>
    </div>
  </div>
  <div class="form-group row">
    <label for="modificado-em" class="col-sm-2 col-form-label">Modificado em: </label>
    <div class="col-sm-10">
      <input type="datetime-local" value={dates.dateModified} id="modificado-em" name="modificado-em" class='form-control'  on:change={(e) => sd.dateModified = formatFulltDate(e.target.value)}>
    </div>
  </div>
  <hr/>

  <h6>Autor: </h6>
  <Person bind:per={sd.author}/>
  <hr/>
  <h6>Publisher: </h6>
  <div class="form-group row">
    <label for="publicado-por" class="col-sm-2 col-form-label">Publicado por: </label>
    <div class="col-sm-10">
      <input id="publicado-por" name="publicado-por" disabled class='form-control' bind:value={sd.publisher.name}/>
    </div>
  </div> 
  <hr/>
  <h6>Logo da Publisher:</h6>
  <ImageObject bind:image={sd.publisher.logo} desabilitar={true} /> 
</div>
