<script>
  export let sd, nameItem;

  $: { nameItem = `${sd.name}`; };
</script>
  
<div>
  <div class="form-group row">
    <label for="web-page-url" class="col-sm-2 col-form-label">Url: </label>
    <div class="col-sm-10">
      <input id="web-page-url" name="web-page-url" class='form-control' bind:value={sd["@id"]}/>
    </div>
  </div>
  <div class="form-group row">
    <label for="web-page-name" class="col-sm-2 col-form-label">Nome: </label>
    <div class="col-sm-10">
      <input id="web-page-name" name="web-page-name" class='form-control' bind:value={sd.name}/>
    </div>
  </div>
  <div class="form-group row">
    <label for="web-page-description" class="col-sm-2 col-form-label">Descrição: </label>
    <div class="col-sm-10">
      <input id="web-page-description" name="web-page-description" class='form-control' bind:value={sd.description}/>
    </div>
  </div>
</div>