<script>
  export let sd;
  import Question from './children/Question.svelte';
  import {json as questionPageJSON} from '../schemaData/children/Question.js';
  
  function appendQuestion(){
    let question_copy = Object.assign({},questionPageJSON);
    question_copy.acceptedAnswer = Object.assign({},questionPageJSON.acceptedAnswer)
    sd.mainEntity = [...sd.mainEntity, question_copy ]
  };
  function removeQuestion(idx){ sd.mainEntity = sd.mainEntity.filter( (e,i) => i !== idx ) }
</script>


<div class="form-group row">
  <div class="col-sm-2 col-form-label">Questões: </div>
  <div class="col-sm-10">
    {#each sd.mainEntity as question, i }
      <div class="relative">
        <div class="col-sm-10">
          <Question bind:que={question}/>
        </div>
        <div class="col-sm-2">
          <button class="remove" on:click|preventDefault={() => removeQuestion(i)}>Remover</button>
        </div>
      </div>
    {/each}
    <button on:click|preventDefault={appendQuestion}>Adicionar Questão</button>
  </div>
</div>


<style>
  .relative { position: relative; }
  .remove { position: absolute; top: 0; right: 0; bottom: 0;}
</style>