<script>
  export let direction;
</script>


<div class="form-group row">
  <label for="instucao-para-direcao" class="col-sm-2 col-form-label">Instrução: </label>
  <div class="col-sm-8">
    <input id="instucao-para-direcao" name="instucao-para-direcao" class='form-control' bind:value={direction.text}/>
  </div>
</div>