<script>
  export let sd;
  export let nameItem;
  import { verifyKeysSD, formatFulltDate, formatDate } from '../../../../lib/helper';
  import {json as jsonFilme} from '../../../schemaData/children/ReviewDataTypes/Filme.js';
  
  if(sd['@type'] == 'Movie' && !Object.keys(sd).includes('director')) sd = JSON.parse(JSON.stringify(jsonFilme));
  
  verifyKeysSD(jsonFilme, sd);
  $: { nameItem = `Filme: ${sd.name}`; };
  let dates = { dateCreated: formatDate(sd.dateCreated) }
</script>

<div>
  <div class="form-group row">
    <label for="movie-link" class="col-sm-2 col-form-label">Link para o filme: </label>
    <div class="col-sm-10">
      <input id="movie-link" name="movie-link" class='form-control'  bind:value={sd.url}/>
    </div>
  </div>
  <div class="form-group row">
    <label for="movie-name" class="col-sm-2 col-form-label">Nome do filme: </label>
    <div class="col-sm-10">
      <input id="movie-name" name="movie-name" class='form-control'  bind:value={sd.name}/>
    </div>
  </div>
  <div class="form-group row">
    <label for="movie-capa" class="col-sm-2 col-form-label">Capa do filme: </label>
    <div class="col-sm-10">
      <input id="movie-capa" name="movie-capa" class='form-control'  bind:value={sd.image}/>
    </div>
  </div>
  <div class="form-group row">
    <label for="movie-release" class="col-sm-2 col-form-label">Data de criacao: </label>
    <div class="col-sm-10">
      <input type="datetime-local" id="movie-release" name="movie-release" class='form-control'  value={dates.dateCreated} on:change={(e) => sd.dateCreated = formatFulltDate(e.target.value)}/>
    </div>
  </div>
  <div class="form-group row">
    <label for="movie-director" class="col-sm-2 col-form-label">Diretor: </label>
    <div class="col-sm-10">
      <input id="movie-director" name="movie-director" class='form-control'  bind:value={sd.director.name}/>
    </div>
  </div>
</div>