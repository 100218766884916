<script>
  export let que;
  import Answer from './Answer.svelte';
</script>

<div>
  <div class="form-group row">
    <label for="question" class="col-sm-2 col-form-label">Pergunta: </label>
    <div class="col-sm-10">
      <input id="question" name="question" class='form-control' bind:value={que.name}/>
    </div>
  </div>
  <Answer bind:answer={que.acceptedAnswer}/>
  <hr/>
</div>