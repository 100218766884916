export default [
  {
    status: false,
    value: 'Monday',
    name: 'Segunda-feira',
    open: '',
    close: ''
  },
  {
    status: false,
    value: 'Tuesday',
    name: 'Terca-feira',
    open: '',
    close: ''
  },
  {
    status: false,
    value: 'Wednesday',
    name: 'Quarta-feira',
    open: '',
    close: ''
  },
  {
    status: false,
    value: 'Thursday',
    name: 'Quinta-feira',
    open: '',
    close: ''
  },
  {
    status: false,
    value: 'Friday',
    name: 'Sexta-feira',
    open: '',
    close: ''
  },
  {
    status: false,
    value: 'Saturday',
    name: 'Sabado',
    open: '',
    close: ''
  },
  {
    status: false,
    value: 'Sunday',
    name: 'Domingo',
    open: '',
    close: ''
  }
] 