<script>
  export let image; 
  export let desabilitar;

</script>

<div>
  <div class="form-group row">
    <label for="url-image-object" class="col-sm-2 col-form-label">Url: </label>
    <div class="col-sm-10">
      <input id="url-image-object" disabled={desabilitar} name="url-image-object" class='form-control' bind:value={image.url}/>
    </div>
  </div>
  <div class="form-group row">
    <label for="altura-da-imagem-image-object" class="col-sm-2 col-form-label">Altura da imagem: </label>
    <div class="col-sm-10">
      <input id="altura-da-imagem-image-object" disabled={desabilitar} name="altura-da-imagem-image-object" class='form-control' type='number' bind:value={image.height}/>
    </div>
  </div>
  <div class="form-group row">
    <label for="largura-da-imagem-image-object" class="col-sm-2 col-form-label">Largura da imagem: </label>
    <div class="col-sm-10">
      <input id="largura-da-imagem-image-object" disabled={desabilitar} name="largura-da-imagem-image-object" class='form-control' type='number' bind:value={image.width}/>
    </div>
  </div>
</div>