<script>
  export let sd, nameItem;
  import PostalAdress from '../schemaEditor/children/PostalAddress.svelte';
  import { formatFulltDate, formatDate } from '../../lib/helper'; 
  import {json as JobPostingJSON} from '../schemaData/JobPosting';
  
  sd = sd ? sd : JSON.parse(JSON.stringify(JobPostingJSON));
  
  // (if) Temporario! @TODO: remover apos atualizar educationRequirements das vagas ja existentes.
  if(typeof(sd.educationRequirements) != 'object') sd.educationRequirements = { "@type" : "EducationalOccupationalCredential", "credentialCategory": "high school", "description" : sd.educationRequirements }
  
  $: { nameItem = `${sd.title}`; };
  let dates = { datePosted: formatDate(sd.datePosted), validThrough: formatDate(sd.validThrough) }

  let jobLocal = {...JobPostingJSON.jobLocation};
  let remoteJob = false;

  const changeLocationJob = () => {
    if(remoteJob){
      delete sd.jobLocation;
      sd.jobLocationType = "TELECOMMUTE";
      sd.applicantLocationRequirements = { "@type": "Country", "name": "BR" };
    }else{
      delete sd.jobLocationType;
      delete sd.applicantLocationRequirements;
      sd.jobLocation = jobLocal;
    }
  }
  const employmentTypes = [ {value: "FULL_TIME", name: "CLT - 8 Horas"}, {value: "PART_TIME", name: "CLT - 6 Horas"}, {value: "CONTRACTOR", name: "Freelancer"}, {value: "TEMPORARY", name: "Temporário"}, {value: "INTERN", name: "Estágio"}, {value: "VOLUNTEER", name: "Voluntário"}, {value: "PER_DIEM", name: "Por dia"}, {value: "OTHER", name: "Outro"}];
  const periodosTypes = [ {value: 'HOUR', name:'Por hora'}, {value: 'DAY', name:'Por dia'}, {value: 'WEEK', name:'Por semana'}, {value: 'MONTH', name:'Por mês'}, {value: 'YEAR', name:'Por ano'}];
  const credentialCategory = [ {name: 'Ensino Médio',value:'high school'}, {name: 'Graduação',value: 'bachelor degree'}, {name: 'Pós-graduação',value:'postgraduate degree'}]
</script>

<div>
  <h6>Sobre a Vaga</h6>
  <div class="form-group row">
    <label for="titulo-da-vaga" class="col-sm-2 col-form-label">Titulo da Vaga: </label>
    <div class="col-sm-10">
      <input id="titulo-da-vaga" name="titulo-da-vaga" class='form-control'  bind:value={sd.title}/>
    </div>
  </div>

  <div class="form-group row mt-3 mb-3">
    <div class="col-sm-2 col-form-label">Descrição da Vaga: </div>
    <div class="col-sm-10">
      <ul class="nav nav-tabs" id="myTab" role="tablist">
        <li class="nav-item" role="presentation">
          <button class="nav-link active" id="html-tab" data-bs-toggle="tab" data-bs-target="#html" type="button" role="tab" aria-controls="html" aria-selected="true">Code - HTML</button>
        </li>
        <li class="nav-item" role="presentation">
          <button class="nav-link" id="preview-tab" data-bs-toggle="tab" data-bs-target="#preview" type="button" role="tab" aria-controls="preview" aria-selected="false">Preview</button>
        </li>
      </ul>
      <div class="tab-content" id="myTabContent">
        <div class="tab-pane fade show active" id="html" role="tabpanel" aria-labelledby="html-tab">
          <textarea rows="5" class='form-control' bind:value={sd.description}></textarea>
        </div>
        <div class="tab-pane fade pb-3 pt-3" id="preview" role="tabpanel" aria-labelledby="preview-tab">
            {@html sd.description}
        </div>
      </div>
    </div>
  </div>
  <div class="form-group row">
    <label for="nivel-de-escolaridade" class="col-sm-2 col-form-label">Nivel de Escolaridade:</label>
    <div class="col-sm-10">
      <select id="nivel-de-escolaridade" name="nivel-de-escolaridade" class='form-select' bind:value={sd.educationRequirements.credentialCategory}>
        <option value="" disabled selected> - Selecione - </option>
          {#each credentialCategory as type}
            <option value={type.value}>{type.name}</option>
          {/each}
      </select>
    </div>
  </div>
  <div class="form-group row">
    <label for="descricao-dos-requerimentos" class="col-sm-2 col-form-label">Descrição dos requerimentos:</label>
    <div class="col-sm-10">
      <input id="descricao-dos-requerimentos" name="descricao-dos-requerimentos" placeholder="Descreva os requerimentos educacionais esperados para a vaga." class='form-control' bind:value={sd.educationRequirements.description}/>
    </div>
  </div>

  <div class="form-group row">
    <label for="periodo-de-experiencia" class="col-sm-2 col-form-label">Período de experiencia (meses): </label>
    <div class="col-sm-10">
      <input id="periodo-de-experiencia" name="periodo-de-experiencia" class='form-control' bind:value={sd.experienceRequirements.monthsOfExperience}/>
    </div>
  </div>

  <div class="form-group row">
    <label for="data-de-publicacao" class="col-sm-2 col-form-label">Data de Publicação: </label>
    <div class="col-sm-10">
      <input type="datetime-local" id="data-de-publicacao" value="{dates.datePosted}" name="data-de-publicacao" class='form-control' on:change={(e) => sd.datePosted = formatFulltDate(e.target.value)}/>
    </div>
  </div>
  <div class="form-group row mb-3">
    <label for="valido-ate" class="col-sm-2 col-form-label">Válido ate: </label>
    <div class="col-sm-10">
      <input type="datetime-local" id="valido-ate" value="{dates.validThrough}" name="valido-ate" class='form-control' on:change={(e) => sd.validThrough = formatFulltDate(e.target.value)}/>
    </div>
  </div>

  <div class="form-group row">
    <label for="tipo-de-emprego" class="col-sm-2 col-form-label">Tipo de Emprego: </label>
    <div class="col-sm-10">
      <select id="tipo-de-emprego" name="tipo-de-emprego" class='form-select' bind:value={sd.employmentType}>
        <option value="" disabled selected> - Selecione - </option>
          {#each employmentTypes as type}
            <option value={type.value}>{type.name}</option>
          {/each}
      </select>
    </div>
  </div>

  <div class="form-group row">
    <div class="col-sm-2 mt-2 col-form-label">Salário base: </div>
    <div class="col-sm-10">
      <div>
        <label for="moeda-salario" class="col-sm-10 col-form-label">Moeda: </label> 
        <input id="moeda-salario" name="moeda-salario" class='form-control'  bind:value={sd.baseSalary.currency}/>
      </div>
      <div class="form-group row">
        <div class="col-sm-5 w-50">
          <label for="valor-do-salario" class="col-sm-10 w-100 col-form-label">Valor {sd.baseSalary.currency !== '' ? `(${sd.baseSalary.currency})` : ''}: </label> 
          <input id="valor-do-salario" name="valor-do-salario" class='form-control' bind:value={sd.baseSalary.value.value}/>
        </div> 
        <div class="col-sm-5 w-50">
          <label for="tipo-de-periodo" class="col-sm-2 w-100 col-form-label">Período: </label>
          <div class="col-sm-10 w-100">
            <select id="tipo-de-periodo" name="tipo-de-periodo" class='form-select' bind:value={sd.baseSalary.value.unitText}>
              <option value="" disabled selected> - Selecione - </option>
                {#each periodosTypes as type}
                  <option value={type.value}>{type.name}</option>
                {/each}
            </select>
          </div>  
        </div>
      </div>
    </div>
  </div>

  <h6 class="mt-3">Sobre a Organização</h6>
  <div class="form-group row">
    <label for="nome-da-empresa" class="col-sm-2 col-form-label">Nome da empresa: </label>
    <div class="col-sm-10">
      <input id="nome-da-empresa" name="nome-da-empresa" class='form-control'  bind:value={sd.hiringOrganization.name}/>
    </div>
  </div>
  <div class="form-group row">
    <label for="link-saiba-mais" class="col-sm-2 col-form-label">Saiba mais (link): </label>
    <div class="col-sm-10">
      <input id="link-saiba-mais" name="link-saiba-mais" class='form-control'  bind:value={sd.hiringOrganization.sameAs}/>
    </div>
  </div>
  <div class="form-group row">
    <label for="logomarca-da-empresa" class="col-sm-2 col-form-label">Logo: </label>
    <div class="col-sm-10">
      <input id="logomarca-da-empresa" name="logomarca-da-empresa" class='form-control'  bind:value={sd.hiringOrganization.logo}/>
    </div>
  </div>

  <div class="form-group row mb-3">
    <div class="col-sm-2 pt-3 col-form-label">Identificador: </div> 
    <div class="col-sm-10">
      <div class="relative">
        <label for="nome-do-identificador" class="col-sm-10 col-form-label">Nome: </label> 
          <input id="nome-do-identificador" name="nome-do-identificador" class="form-control" type="text" bind:value={sd.identifier.name}>
      </div>
      <div class="relative">
        <label for="valor-unico-do-identificador" class="col-sm-10 col-form-label">Valor único (CNPJ, Domínio, ...): </label> 
          <input id="valor-unico-do-identificador" name="valor-unico-do-identificador" class="form-control" type="text" bind:value={sd.identifier.value}>
      </div> 
    </div>
  </div>

  <h6>Localização</h6>
  <div class="form-group row">
    <div class="col-sm-2 col-form-label">Local de trabalho:</div> 
    <div class="col-sm-10">
      <label for="vaga-de-trabalho-remoto" class="col-sm" >
        <input id="vaga-de-trabalho-remoto" name="vaga-de-trabalho-remoto" type="checkbox" bind:checked={remoteJob} on:change={changeLocationJob}> 
        Vaga de trabalho remoto
      </label>
      {#if remoteJob && sd.applicantLocationRequirements} 
      <div class="form-group row">
        <label for="pais-base-do-emprego" class="col-sm-2 col-form-label">País base: </label>
        <div class="col-sm-10">
          <input id="pais-base-do-emprego" name="pais-base-do-emprego" class='form-control'  bind:value={sd.applicantLocationRequirements.name}/>
        </div>
      </div>
      {:else if !remoteJob && sd.jobLocation}
        <div class="col-sm mt-3">
          <PostalAdress bind:address={sd.jobLocation.address} />
        </div>
      {/if}
    </div>
  </div>
</div>