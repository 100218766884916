<script>
  import { formatFulltDate, formatDate } from '../../lib/helper'; 
  export let sd;

  function appendThumbnail(){ sd.thumbnailUrl = [...sd.thumbnailUrl, '']; };
  function removeThumbnail(idx){ sd.thumbnailUrl = sd.thumbnailUrl.filter( (e,i) => i !== idx ) }

  let dates = { uploadDate: formatDate(sd.uploadDate) }
  
</script>

<div>
  <div class="form-group row">
    <label for="vid-obj-name" class="col-sm-2 col-form-label">Nome: </label>
    <div class="col-sm-10">
      <input id="vid-obj-name" name="vid-obj-name" class='form-control' bind:value={sd.name}/>
    </div>
  </div>

  <div class="form-group row">
    <label for="vid-obj-description" class="col-sm-2 col-form-label">Descrição: </label>
    <div class="col-sm-10">
      <input id="vid-obj-description" name="vid-obj-description" class='form-control' bind:value={sd.description}/>
    </div>
  </div>
  <hr/>
  {#if sd.thumbnailUrl}
    <h6>Thumbnail</h6>
    {#each sd.thumbnailUrl as thumbnail, i}
    <div class="form-group row">
      <div class="col-sm-10">
        <input class='form-control' bind:value={thumbnail}/>
      </div>
      <div class="col-sm-2">
        <button class="remove btn btn-danger w-100" on:click|preventDefault={() => removeThumbnail(i)}>Remover</button>
      </div>
    </div>
    {/each}
  {/if}
  <button class="add mt-2 mb-4 btn btn-primary w-100" on:click|preventDefault={appendThumbnail}>Adicinar thumbnail</button>

  <div class="form-group row">
    <label for="vid-obj-data-up" class="col-sm-2 col-form-label">Data de Upload: </label>
    <div class="col-sm-10">
      <input type="datetime-local" id="vid-obj-data-up" name="vid-obj-data-up" class='form-control'  value="{dates.uploadDate}" on:change={(e) => sd.uploadDate = formatFulltDate(e.target.value)}/>
    </div>
  </div>

  <div class="form-group row">
    <label for="vid-obj-duration" class="col-sm-2 col-form-label">Duração: </label>
    <div class="col-sm-10">
      <input id="vid-obj-duration" name="vid-obj-duration" class='form-control' bind:value={sd.duration}/>
    </div>
  </div>

  <div class="form-group row">
    <label for="vid-obj-url-acess" class="col-sm-2 col-form-label">URL acesso: </label>
    <div class="col-sm-10">
      <input id="vid-obj-url-acess" name="vid-obj-url-acess" class='form-control' bind:value={sd.contentUrl}/>
    </div>
  </div>

  <div class="form-group row">
    <label for="vid-obj-url-anexo" class="col-sm-2 col-form-label">URL anexar: </label>
    <div class="col-sm-10">
      <input id="vid-obj-url-anexo" name="vid-obj-url-anexo" class='form-control' bind:value={sd.embedUrl}/>
    </div>
  </div>

  <div class="form-group row">
    <label for="vid-obj-view" class="col-sm-2 col-form-label">Quantidade de views: </label>
    <div class="col-sm-10">
      <input id="vid-obj-view" name="vid-obj-view" class='form-control' bind:value={sd.interactionStatistic.userInteractionCount}/>
    </div>
  </div>

  <div class="form-group row">
    <label for="vid-obj-reg" class="col-sm-2 col-form-label">Regiões permitidas: </label>
    <div class="col-sm-10">
      <input id="vid-obj-reg" name="vid-obj-reg" class='form-control' maxlength="2" bind:value={sd.regionsAllowed}/>
    </div>
  </div>
</div>