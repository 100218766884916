export const verifyKeysSD = (defaltSD, currentSD) => {
  Object.keys(defaltSD).forEach(key => {
    if(!Object.keys(currentSD).includes(key)) currentSD[key] = defaltSD[key];
  });
}

export const formatFulltDate = (date) => {
  const formatedDate = new Date(date);
  formatedDate.setMinutes(formatedDate.getMinutes() - formatedDate.getTimezoneOffset()); // GTM - 03:00
  
  const dataDate = getDataDate(formatedDate);
  return `${dataDate.year}-${dataDate.month}-${dataDate.day}T${dataDate.hour}:${dataDate.minutes}:${dataDate.seconds}-03:00`;
}; // => yy-mm-ddTHH:MM-SS(TimeZone)

export const formatDate = (date) => {
  date = new Date(date)
  date.setMinutes(date.getMinutes() + date.getTimezoneOffset()); // GTM 00:00

  const dataDate = getDataDate(date);
  return `${dataDate.year}-${dataDate.month}-${dataDate.day}T${dataDate.hour}:${dataDate.minutes}-00:00`;
}; // => yy-mm-ddTHH:MM

const getDataDate = (date) => {
  return {
    year:    date.getFullYear(),
    month:   ("0" + (date.getMonth() + 1)).slice(-2), // 00...11
    day:     ("0" + (date.getDate())).slice(-2),
    hour:    ("0" + (date.getHours())).slice(-2),
    minutes: ("0" + (date.getMinutes())).slice(-2),
    seconds: ("0" + (date.getSeconds())).slice(-2),
  }
}