<script>
  import {writable} from 'svelte/store';
  import {flip} from 'svelte/animate';
  export let parsedRedirects; // Receives parsed from JSN as an array 
  export let outputTo; // Receives form element
  
  let persistCheckboxes = {};
  let redirects = writable(parsedRedirects);
  let redirectAdd = {id: null, from: null, to: null, type: '301'};
  let filter = null;
  let selectedIds = [];
  let filteredRedirects = [];
  let hovering = null;

  // Auto-update form content
  const unsubscribe = redirects.subscribe(value => {
    outputTo.value = JSON.stringify(value);
  });
  
  function append(){ 
    if(
      !redirectAdd.from 
      || !redirectAdd.to 
      || !redirectAdd.type
    ) return alert("Preencha corretamente os campos de Origem e Destino!");
    redirectAdd.id = getTimeStamp();
    $redirects = [...$redirects, redirectAdd];
    redirectAdd = {id: null, from: null, to: null, type: '301'};
  }

  function remove(id){ $redirects = $redirects.filter( (e) => e.id !== id ); }

  function removeMultiples(){ $redirects = $redirects.filter( (e) => selectedIds.indexOf(e.id) < 0 );}

  function toggleSelectedIds(e){
    let id = parseInt(e.target.value);
    let index = selectedIds.indexOf(id);
    if(index < 0){
      selectedIds.push(id);
      persistCheckboxes[id] = true
    }else{
      selectedIds.splice(index, 1);
      persistCheckboxes[id] = false
    }
  }

  function selectAll(){
    filteredRedirects.forEach(id => {persistCheckboxes[id] = true})
    selectedIds = [... new Set(selectedIds.concat(filteredRedirects))];
  }

  function getTimeStamp(){return new Date().getTime()}

  const dragDrop = (event, target) => {
    event.dataTransfer.dropEffect = 'move'; 
    const start = parseInt(event.dataTransfer.getData("text/plain"));
    const newTracklist = $redirects

    if (start < target) {
      newTracklist.splice(target + 1, 0, newTracklist[start]);
      newTracklist.splice(start, 1);
    } else {
      newTracklist.splice(target, 0, newTracklist[start]);
      newTracklist.splice(start + 1, 1);
    }
    $redirects = newTracklist
    hovering = null
  }

  const dragStart = (event, i) => {
    event.dataTransfer.effectAllowed = 'move';
    event.dataTransfer.dropEffect = 'move';
    const start = i;
    event.dataTransfer.setData('text/plain', start);
  }

  $: if(filter){
    filteredRedirects = $redirects.filter(redirect => (redirect.to.includes(filter) || redirect.from.includes(filter))).map(redirect => redirect.id);
  }else{ filteredRedirects = $redirects.map(redirect => redirect.id) }
</script>

<div class="search-filter">
  <input type="text" id="filter" bind:value={filter}>
  <span class="material-icons">search</span>
  <div class="show-info">
    Total: <b>{$redirects.length}</b> | Exibindo: <b>{filteredRedirects.length}</b>
  </div>
  
</div>

<div class="redirects-editor">
  <div class="redirect-add">
    <div>
      <label for="redirect-from-add">Path Origem</label><br>
      <input type="text" name="redirect-from-add" id="redirect-from-add" bind:value={redirectAdd.from}>
    </div>
    <div>
      <label for="redirect-to-add">Url ou Path destino</label><br>
      <input type="text" name="redirect-to-add" id="redirect-to-add"  bind:value={redirectAdd.to}> 
    </div>
    <div>
      <label for="redirect-type-add">Tipo</label><br>
      <input type="text" name="redirect-type-add" style="text-align: center" id="redirect-type-add"  bind:value={redirectAdd.type}>  
    </div>
    <div class="button-container">
      <button class="button add-button" on:click|preventDefault={() => append()}><span class="material-icons">add_circle_outline</span></button>
    </div>
  </div>
  <hr>
  <div>
    <button on:click={selectAll} class="btn btn-secondary">Selecionar todos</button> 
    <button on:click={removeMultiples} class="btn btn-danger">Remover selecionados</button>
  </div>
  <hr>

  {#each $redirects as redirect, i}
    {#if (!filter) || (redirect.to.includes(filter) || redirect.from.includes(filter))}
    <div 
      class="redirect-item"
      animate={flip}
      draggable={true} 
      on:dragstart={event => dragStart(event, i)}
      on:drop|preventDefault={event => dragDrop(event, i)}
      ondragover="return false"
      on:dragenter={() => hovering = i}
      class:is-active={hovering === i}
    >
      <div style="display: flex">
        <input type="checkbox" class="select-multiple"
         id={`select-${redirect.id}`}  
         name={`select-${redirect.id}`} 
         value="{redirect.id}" 
         on:change={(e) => toggleSelectedIds(e)}
         bind:checked={persistCheckboxes[redirect.id]}
        >
      </div>
      <input type="text" id={`redirect-from-${i}`} bind:value={redirect.from}>
      <input type="text" id={`redirect-to-${i}`} bind:value={redirect.to}>
      <input type="text" style="text-align: center" id={`redirect-type-${i}`} bind:value={redirect.type} disabled>
      <button class="button remove-button" on:click|preventDefault={() => remove(redirect.id)}><span class="material-icons">delete_forever</span></button>
    </div>
    {/if}
  {/each}
</div>

<style>
  .redirects-editor { background: white; border-radius: 5px; padding: 10px}
  .redirect-item {padding: 10px; margin: 5px; display: grid; grid-template-columns: 20px 1fr 1fr 70px auto; gap: 5px; }
  .redirect-item.is-active{background-color: #3273dc;}
  .redirect-add {padding: 10px; margin: 5px; display: grid; grid-template-columns: 1fr 1fr 70px auto; gap: 5px; }
  .redirect-item:nth-child(2n+1){background-color: #e2e2e2;}
  .redirect-item input, .redirect-add input{width: 100%; padding: 5px}
  .select-multiple{height: 20px; align-self: center}
  .button-container{display: flex; align-items: flex-end;}
  .button{display: flex;align-items: center;color: white;border: 0; border-radius: 5px; height: 38px; }
  .button:hover{ opacity: 0.7; }
  .add-button{background: #445807;}
  .remove-button{background: #c50000;}
  .search-filter{position: relative; margin-bottom: 20px;}
  .search-filter span{position: absolute; right: 20px;top: 17%;color: #9b9b9b;}
  .search-filter #filter{width: 100%; height: 45px; padding: 15px;}
  .search-filter .show-info{ color: #9b9b9b; text-align: end;}
</style>