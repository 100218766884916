/* eslint no-console: 0 */

import RedirectsEditor from '../svelte/redirects_editor.svelte'

document.addEventListener('turbo:load', () => {
  function parseRedirects(){
    let raw = document.getElementById('site_redirects').value;
    let parsed = [];
    try{parsed = JSON.parse(raw)}catch(e){}
    return parsed.constructor == Array ? parsed : [parsed];
  }

  if(document.getElementById('redirects-editor')){
    const redirectsEditor = new RedirectsEditor({
      target: document.getElementById('redirects-editor'),
      props: {
        parsedRedirects: parseRedirects(),
        outputTo: document.getElementById('site_redirects')
      }
    });

    window.redirectsEditor = redirectsEditor;
  }
})

