export let json = {
  "@context" : "https://schema.org/",
  "@type" : "JobPosting",
  "title" : "Vaga Custumer Sucess", 
  "educationRequirements" : {
    "@type" : "EducationalOccupationalCredential",
    "credentialCategory": "high school",
    "description" : ""
  },
  "experienceRequirements" : {
    "@type" : "OccupationalExperienceRequirements",
    "monthsOfExperience" : "36"
  },
  "description": "<p>A Real Seguro Viagem é a primeira empresa de comparação online para o seguro viagem do Brasil. Estamos construindo um time de vendedores (inside sales), de alta excelência. Trabalhamos com processos bem estruturados para que você atinja e cresça com os melhores resultados!</p><p>O que esperamos que você saiba:</p><ul><li>Entender o do lead e a real necessidade dele para ser assertivo na solução;</li><li>Conduzir as reuniões de forma consultiva, sempre com foco em geração de valor para o lead (não precisa prospectar);</li><li>Fazer follow ups (ligações, email, WhatsApp) mantendo o CRM sempre atualizado e acompanhar o lead até a volta da viagem;</li><li>Capacidade de organização para lidar com alto volume de contatos e gerenciamento de CRM;</li><li>Ser orientado por prazos e metas;</li></ul><p>Obrigatório:</p><ul><li>2 anos ou mais de experiência profissional em vendas;</li><li>Conhecimento de técnicas de vendas (Ex: spin selling);</li><li>Saber se expressar bem de forma escrita;</li><li>Capacidade de se comunicar eficientemente por telefone, chat, videoconferencia;</li></ul><p>Diferenciais:</p><ul><li>Formação na área de Comunicação;</li><li>Ter cursado treinamentos de vendas;</li><li>Vivência com comunicação persuasiva</li><p>O que oferecemos:</p><ul><li>Trabalho integralmente da sua casa (Home Office);</li><li>Trabalhar com uma equipe distribuída nacionalmente;</li><li>Registro em CLT (44 Horas semanais) com Remuneração competitiva;</li><li>Auxílio Alimentação e Internet.</li></ul>",
  "identifier": { 
    "@type": "PropertyValue",
    "name": "Real Seguro Viagem",
    "value": "https://www.seguroviagem.srv.br/"
  },
  "datePosted" : "2017-01-18",
  "validThrough" : "2017-03-18T00:00",
  "employmentType" : "CONTRACTOR",
  "hiringOrganization" : {
    "@type" : "Organization",
    "name" : "Real Seguro Viagem",
    "sameAs" : "https://www.seguroviagem.srv.br/",
    "logo" : "https://a1.seguroviagem.srv.br/img/favicons/android-chrome-192x192.png"
  },
  "jobLocation": {
    "@type": "Place",
    "address": {
    "@type": "PostalAddress",
    "streetAddress": "1600 Amphitheatre Pkwy",
    "addressLocality": "Mountain View",
    "addressRegion": "CA",
    "postalCode": "94043",
    "addressCountry": "US"
    }
  },
  "applicantLocationRequirements": {
    "@type": "Country",
    "name": "BR"
  },
 "baseSalary": {
    "@type": "MonetaryAmount",
    "currency": "R$",
    "value": {
      "@type": "QuantitativeValue",
      "value": 4000.00,
      "unitText": "MONTH"
    }
  }
};
