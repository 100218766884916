<script>
  import { afterUpdate } from 'svelte';

  import {tipoPorNome} from '../schemas.js';
  export let structuredDatas;
  let nameItem = [];

  function remove(idx){ structuredDatas = structuredDatas.filter( (e,i) => i !== idx ) }

  let hovering = false;

  const drop = (event, target) => {
    event.dataTransfer.dropEffect = 'move';
    const start = parseInt(event.dataTransfer.getData("text/plain"));
    const newTracklist = structuredDatas;

    if (start < target) {
      newTracklist.splice(target + 1, 0, newTracklist[start]);
      newTracklist.splice(start, 1);
    } else {
      newTracklist.splice(target, 0, newTracklist[start]);
      newTracklist.splice(start + 1, 1);
    }
    structuredDatas = newTracklist;
    hovering = null;
  }

  const dragstart = (event, i) => {
    event.dataTransfer.effectAllowed = 'move';
    event.dataTransfer.dropEffect = 'move';
    const start = i;
    event.dataTransfer.setData('text/plain', start);
  }
  const hadleEnterFromInputs = () =>{
    document.querySelectorAll('input').forEach(el => {
      el.addEventListener('keypress', e => {if(e.key === 'Enter') e.preventDefault()})
    });
  }

  const returnTitleComponent = (sd, id) => {
    return `${tipoPorNome(sd['@type']).name}${(nameItem[id] !== undefined)  ? ` - ${nameItem[id]}` : ''}`;
  }

  afterUpdate(hadleEnterFromInputs);
</script>

{#each structuredDatas as sd, i}
  <div class="sd-item shadow-sm bg-white rounded">
    <div
       class="header-item list-item"
       draggable={true}
       on:dragstart={event => dragstart(event, i)}
       on:drop|preventDefault={event => drop(event, i)}
       ondragover="return false"
       on:dragenter={() => hovering = i}
       class:is-active={hovering === i}
    >
      <div class="header-left" title="mover">
        <div class="header--icon-drag">
          {#each {length: 6} as _, i}
            <i class="header--icon-circle"></i>
          {/each}
        </div>
        <h5 class="title-component">
          {returnTitleComponent(sd, i)}
        </h5>
      </div>
      <div class="btns header-right">
        <button
          data-bs-toggle="collapse"
          data-bs-target="#collapse-{i}"
          aria-expanded="false"
          aria-controls="collapse-{i}"
          class="icon-min"
          title="icon-minimizar item"
          on:click|preventDefault>&#8722;</button>
        <button class="remove" title="Deletar item" on:click|preventDefault={() => {if(confirm('Você tem certeza?')) remove(i); }}>&#10005;</button>
      </div>
    </div>
  </div>
  <div class="collapse item-body" id="collapse-{i}">
    <svelte:component this={tipoPorNome(sd['@type']).component} bind:sd={sd} bind:nameItem={nameItem[i]}/>
  </div>
{/each}

<style>
  .sd-item { border: 1px solid #ccc; overflow: hidden; transition: all ease .3s; margin: 5px; position: relative; border-radius: 5px;}
  .header-item{display: flex; align-items: center; justify-content: space-between; padding: 10px;}
  .btns{display: flex; align-items: center;}
  .btns button{margin-right: 10px; border-radius: 5px; border: none; outline: none;}
  .icon-min{color: white; background-color: #0d6efd; font-weight: bold; padding: 1px 8px;}
  .remove{ color: white; background-color: #ff5b66;}
  .item-body{ padding: 5px 12px; padding-top: 20px; margin: 20px 7px 0 7px; border: 1px solid #ccc; border-radius: 10px;}

  .list-item:not(:last-child) { border-bottom: 1px solid #dbdbdb; }
  .list-item.is-active { background-color: #3273dcc4; color: #fff; }
  .header-left{ display: flex; align-items: center; justify-content: flex-start;}
  .header-left h5{margin: 0;}
  .header--icon-drag{width: 10px; height: 100%; display: grid; grid-template-columns: repeat(2, 1fr); cursor: move; gap: 3px; margin-right: 10px;}
  .header--icon-circle{width: 3px; height: 3px; border-radius: 50%; background-color: #ccc;}

  .title-component{ display: flex; align-items: center; justify-content: flex-start; }
</style>
