<script>
  export let structuredDatas;
  export let content;
  $: countReviewInTB = content.split('__REVIEW__').length - 1
  $: countReviewInSD = structuredDatas.filter(el => el['@type'] == 'Review').length;
  $: valid = countReviewInSD == countReviewInTB
</script>

{#if !valid}
  <small class="invalid">
    ⚠️ Atenção: 
    Você inseriu abaixo {countReviewInSD} componente(s) de review porém 
    foram identificados {countReviewInTB} no conteúdo.
  </small>
{/if}

<style>
  .invalid{ display: block; margin-top: 1rem; color: red; background: #f002;
    padding: 20px;}
</style>