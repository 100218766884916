<script>
  import ListItems from './schemaEditor/ListItems.svelte';
  import AlertStructuredDataDifference from './schemaEditor/AlertStructuredDataDifference.svelte';
  import {writable} from 'svelte/store';
  import {tipos} from './schemas.js';
  export let sds, outputTo, content, onChange;

  let loadedContent;
  let structuredDatas = writable(sds);
  let updateContent = () => loadedContent = content()

  const unsubscribe = structuredDatas.subscribe(value => {
    outputTo.value = JSON.stringify(value);
    updateContent()
  });
  const append = (tipo_idx) => {  $structuredDatas = [...$structuredDatas, tipos[tipo_idx].json]  };
  onChange(updateContent);

  updateContent();
</script>

<div class="sd-editor">
  <div>
    <div class="actions">
      <span>Adicionar: </span> <br>
      <span class="btn-group">
        {#each tipos as tipo, i}
          <button on:click|preventDefault={append.bind(this,i)} class="btn btn-sm btn-outline-primary">
            {tipo.name}
          </button>
        {/each}
      </span>
    </div>
    <AlertStructuredDataDifference content={loadedContent} structuredDatas={$structuredDatas}/>
  </div>
  <hr/>
  <ListItems bind:structuredDatas={$structuredDatas}/>
</div>

<style>
  .sd-editor { background: white; border-radius: 5px; padding: 10px}
</style>
