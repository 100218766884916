<script>
  export let address;
</script>

{#if address != undefined}
  <div class="form-group row">
    <label for="postal-adress-rua" class="col-sm-2 col-form-label">Rua: </label>
    <div class="col-sm-10">
      <input id="postal-adress-rua" name="postal-adress-rua" class='form-control' bind:value={address.streetAddress}/>
    </div>
  </div>
  <div class="form-group row">
    <label for="postal-adress-city" class="col-sm-2 col-form-label">Cidade: </label>
    <div class="col-sm-10">
      <input id="postal-adress-" name="postal-adress-city" class='form-control' bind:value={address.addressLocality}/>
    </div>
  </div>
  <div class="form-group row">
    <label for="postal-adress-region" class="col-sm-2 col-form-label">Região: </label>
    <div class="col-sm-10">
      <input id="postal-adress-region" name="postal-adress-region" class='form-control' bind:value={address.addressRegion}/>
    </div>
  </div>
  <div class="form-group row">
    <label for="postal-adress-cep" class="col-sm-2 col-form-label">CEP: </label>
    <div class="col-sm-10">
      <input id="postal-adress-cep" name="postal-adress-cep" class='form-control' bind:value={address.postalCode}/>
    </div>
  </div>
  <div class="form-group row">
    <label for="postal-adress-pais" class="col-sm-2 col-form-label">País: </label>
    <div class="col-sm-10">
      <input id="postal-adress-pais" name="postal-adress-pais" class='form-control' bind:value={address.addressCountry}/>
    </div>
  </div>
{/if}