import * as Turbo from '@hotwired/turbo'
// import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import "../bootstrap/tooltip"
import '../packs/trumbo/editor'
import "../packs/redirects_editor"
// import "channels"
// import "jquery"
// import "trix"
// import "@rails/actiontext"
import '../packs/sd_editor'
import '../packs/real_content_blocks'


Turbo.start()
ActiveStorage.start()

// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')
// Example: Import a stylesheet in app/frontend/index.css
// import '~/index.css'

console.log('Vite ⚡️ Rails')
console.log('Visit the guide for more information: ', 'https://vite-ruby.netlify.app/guide/rails')


$(document).on('turbo:load', function() {
  // TOOLTIPS
  if($("body").tooltip) $("body").tooltip({ selector: '[data-bs-toggle=tooltip]' });

  // Site#Form
  var prefixed = $("#page_permalink").val() || '';
  if(typeof(prefixed) == 'undefined') return;
  var prefixo;
  prefixo = prefixed.split('/')[0]+'/';
  $("#page_categories").on('change', function() {
    $("#page_permalink").val(prefixo + $("#page_categories option:selected").text()+'/');
  });
  $("#page_description").on('blur',function(){
    let length = $("#description_length");
    length.css('color', (length.text() < 70 ? '#f00' : '#999') );
  })
});
