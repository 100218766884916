import "trumbowyg"
import "trumbowyg/dist/trumbowyg.min.js"
import "trumbowyg/dist/plugins/upload/trumbowyg.upload.min.js"
import "trumbowyg/dist/plugins/cleanpaste/trumbowyg.cleanpaste.min.js"

import "./plugins/trumbowyg.allowtagsfrompaste.js"
import "./plugins/trumbowyg.figcaption.js"

document.addEventListener('turbo:load', () => {
  $('#content').trumbowyg({
    svgPath: '/images/icons-trumbowyg.svg',
    resetCss: false,
    imageWidthModalEdit: true,
    semantic: {
      'div': 'div' // Editor does nothing on div tags now
    },
    btnsDef: {
      RealButtons: {
        title: '💜',
        hasIcon: false,
        dropdown: ['figcaption'],
      }
    },
    btns: [
      ['viewHTML'],
      ['undo', 'redo'], // Only supported in Blink browsers
      ['formatting'],
      ['strong', 'em', 'del'],
      ['superscript', 'subscript'],
      ['link'],
      ['insertImage', 'upload'],
      ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull'],
      ['unorderedList', 'orderedList'],
      ['horizontalRule'],
      ['removeformat'],
      ['RealButtons'],
      ['fullscreen'],
    ],
    plugins: {
      figcaption: {
        serverPath: '/api/uploads',
        fileFieldName: 'file'
      },
      allowTagsFromPaste: {
        allowedTags: [ 'a', 'em', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'hr', 'br', 'strike', 'sup', 'sub', 'img', 'i', 'small', 'strong', 'u', 'p', 'li', 'ol']
      }
    }
  });
});
