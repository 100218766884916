<script>
  import PostalAddress from './PostalAddress.svelte';
  export let location;
</script>

<h6>Local</h6>
<div class="form-group row">
  <label for="place-name" class="col-sm-2 col-form-label">Nome do local: </label>
  <div class="col-sm-10">
    <input id="place-name" name="place-name" class='form-control' bind:value={location.name}/>
  </div>
</div>

<PostalAddress bind:address={location.address} />