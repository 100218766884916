/* eslint no-console: 0 */

import App from '../svelte/sd_editor.svelte'

document.addEventListener('turbo:load', () => {
  function parseLdJson(){
    let raw = document.getElementById('page_ld_json').value;
    let parsed = [];
    try{
      parsed = JSON.parse(raw);
    }catch(e){}
    
    let ld = parsed.constructor == Array ? parsed : [parsed];
    
    return ld;
  }

  if(document.getElementById('sd-editor')){
    const app = new App({
      target: document.getElementById('sd-editor'),
      props: {
        sds: parseLdJson(),
        outputTo: document.getElementById('page_ld_json'),
        content: () => { return $('#content').trumbowyg('html') },
        onChange: (callback) => { $('#content').on('tbwchange', () => callback()) }
      }
    });

    window.app = app;
  }
})
