<script>
  import Livro from './children/ReviewComponents/Livro.svelte';
  import Receita from './children/ReviewComponents/Receita.svelte';
  import Filme from './children/ReviewComponents/Filme.svelte';
  import Event from './children/ReviewComponents/Event.svelte';
  import EmpresaLocal from './children/ReviewComponents/EmpresaLocal.svelte';
  import optionsGroup from './children/ReviewComponents/Data/optgroup';
  export let sd, nameItem;

  sd = JSON.parse(JSON.stringify(sd));
  
  const empresaLocal = () => {
    let status = false;
    if(sd.itemReviewed["@type"] === 'LocalBusiness') status = true;
    optionsGroup.forEach(group => {
      group.options.forEach(opt=>{
        if(opt.replace(/ /g, '').includes(sd.itemReviewed["@type"])) status = true;
      });
    });
    return status;
  };
  
  let localBusiness = empresaLocal() ? sd.itemReviewed["@type"] : 'LocalBusiness';
</script>
<section>
  <div class="form-group row">
    <label for='name-review' class="col-sm-2 col-form-label">Nome da Review: </label>
    <div class="col-sm-10">
      <input class='form-control' id='name-review' name='name-review' bind:value={sd.name}/>
    </div>
  </div>
  <hr>

  <div class="form-group row">
    <label for="corpo-review" class="col-sm-2 col-form-label">Texto da Review: </label>
    <div class="col-sm-10">
      <textarea class='form-control' id="corpo-review" name="corpo-review" bind:value={sd.reviewBody}/>
    </div>
  </div> 
  <div class="form-group row">
    <label for="author-name" class="col-sm-2 col-form-label">Autor do review: </label>
    <div class="col-sm-10">
      <input class='form-control' id="author-name" name="author-name" bind:value={sd.author.name}/>
    </div>
  </div>
  <div class="form-group row">
    <label for="review-rating" class="col-sm-2 col-form-label">Nota do review: </label>
    <div class="col-sm-10">
      <input 
        type="number"
        step="0.1"
        min="0"
        max="5"
        class='form-control' 
        id="review-rating" 
        name="review-rating" 
        bind:value={sd.reviewRating.ratingValue}/>
    </div>
  </div> 
  <div class="form-group row">
    <label for="publisher-name" class="col-sm-2 col-form-label">Publicado por: </label>
    <div class="col-sm-10">
      <input class='form-control' id="publisher-name" name="publisher-name" bind:value={sd.publisher.name}/>
    </div>
  </div> 

  <div class="form-group row">
    <label for='item-review' class="col-sm-2 col-form-label">Item da review: </label>
    <div class="col-sm-10">
      
      <select class='form-select' id='item-review' name="item-review" bind:value={sd.itemReviewed['@type']}>
        <option value="" disabled selected>Selecione um tipo de review</option>
        <option value={localBusiness}>Empresa Local</option>
        <option value="Book">Livro</option>
        <option value="Recipe">Receita</option>
        <option value="Movie">Filme</option>
        <option value="Event">Evento</option>
      </select>
    </div>
  </div>
  <br/><hr><br/>
  
  {#if sd.itemReviewed['@type'] === 'Book'}
    <h5>Livro</h5>
    <Livro bind:sd={sd.itemReviewed} bind:nameItem={nameItem}/>
  {:else if sd.itemReviewed['@type'] === 'Recipe'}
    <h5>Receita</h5>
    <Receita bind:sd={sd.itemReviewed} bind:nameItem={nameItem}/>
  {:else if sd.itemReviewed['@type'] === 'Movie'}
    <h5>Filme</h5>
    <Filme bind:sd={sd.itemReviewed} bind:nameItem={nameItem}/>
  {:else if sd.itemReviewed['@type'] === 'Event'}
    <h5>Evento</h5>
    <Event bind:sd={sd.itemReviewed} bind:nameItem={nameItem}/>
  {:else if empresaLocal() || sd.itemReviewed['@type'] === 'LocalBusiness'}
    <h5>Empresa Local</h5>
    <EmpresaLocal bind:sd={sd.itemReviewed} bind:nameItem={nameItem}/>
  {/if}  
</section>


