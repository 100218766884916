document.querySelectorAll('[role="real-code-block"]').forEach(button => {
  let modelFunction = button.getAttribute("model");
  button.addEventListener("click", function(event){
    event.preventDefault(); 
    if(modelFunction == 'title'){
      addTitle(appendContent[modelFunction]());
    }else {
      addCodeBlock(`<br/>${appendContent[modelFunction]()}<br/>`);
    }
  });
});

// TEXTOS PARA USAR DE EXEMPLO NOS BLOCOS

var twoHundredWords = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. ";
twoHundredWords += "Donec at iaculis orci. Donec sit amet nunc libero. Morbi vitae ";
twoHundredWords += "acilisis arcu. Nunc semper sollicitudin turpis, ac maximus risus. ";
twoHundredWords += "Nunc ullamcorper iaculis augue, sed sagittis arcu congue eget. ";
twoHundredWords += "Quisque rutrum arcu orci, vitae cursus enim venenatis et. ";
twoHundredWords += "Curabitur nisl turpis, imperdiet rutrum eros a, consequat ullamcorper ";
twoHundredWords += "elit. In mollis lacinia tortor, non vestibulum mauris vehicula nec. ";
twoHundredWords += "Maecenas nec augue tincidunt, imperdiet purus nec, dapibus velit. Quisque ";
twoHundredWords += "ac libero eget eros condimentum pellentesque. Cras varius lorem ac ante ";
twoHundredWords += "sollicitudin scelerisque. Maecenas sit amet congue magna, eu varius sem. ";
twoHundredWords += "Proin venenatis odio ac neque varius tempus. Sed orci nunc, porta id semper ac, ";
twoHundredWords += "gravida scelerisque tortor. Donec facilisis placerat diam. Nullam rutrum posuere eleifend.";
twoHundredWords += "<br>";
twoHundredWords += "Sed magna sapien, semper eget sem a, placerat mattis quam. Sed mattis sem semper risus ";
twoHundredWords += "convallis, at aliquet tellus convallis. Mauris condimentum imperdiet lorem ut accumsan. ";
twoHundredWords += "Fusce at urna facilisis urna mollis rhoncus. Maecenas dolor ante, interdum eleifend porta ";
twoHundredWords += "quis, vestibulum at ante. Orci varius natoque penatibus et magnis dis parturient montes, ";
twoHundredWords += "nascetur ridiculus mus. Fusce nec leo vitae augue vestibulum sagittis eu in lectus. Aliquam ";
twoHundredWords += "rhoncus magna eu erat elementum euismod. Donec et egestas enim, ac ullamcorper arcu.";

var threeHundredWords = twoHundredWords + '<br>';
threeHundredWords += "Sed magna sapien, semper eget sem a, placerat mattis quam. Sed mattis sem semper risus ";
threeHundredWords += "convallis, at aliquet tellus convallis. Mauris condimentum imperdiet lorem ut accumsan. ";
threeHundredWords += "Fusce at urna facilisis urna mollis rhoncus. Maecenas dolor ante, interdum eleifend porta ";
threeHundredWords += "quis, vestibulum at ante. Orci varius natoque penatibus et magnis dis parturient montes, ";
threeHundredWords += "nascetur ridiculus mus. Fusce nec leo vitae augue vestibulum sagittis eu in lectus. Aliquam ";
threeHundredWords += "rhoncus magna eu erat elementum euismod. Donec et egestas enim, ac ullamcorper arcu.";

// BLOCOS DE CONTEÚDO

const title = () => {
  var titleInput = document.getElementById('page_title')
  let text = titleInput.value != ''? titleInput.value : 'Coloque seu titulo aqui';
  return `<h1>${text}</h1>`;
}

function sixHundredWordsThreeColumns(){
  let code = '';

  code += `<div class="content-container">`;
  code += `<div><h2>O que compõem o seguro viagem?</h2></div>`;
  code += `<div class="content-three-columns">`;
  code += `<div>`;
  code += `<h3>O que compõem o seguro viagem?</h3>`;
  code += `<div>${twoHundredWords}</div>`;
  code += `</div>`;
  code += `<div>`;
  code += `<h3>O que eu ganho comprando o seguro?</h3>`;
  code += `<div>${twoHundredWords}</div>`;
  code += `</div>`;
  code += `<div>`;
  code += `<h3>O que não é o seguro viagem?</h3>`;
  code += `<div>${twoHundredWords}</div>`;
  code += `</div>`;
  code += `</div></div>`;

  return code;
}

function sixHundredWordsTwoColumns(){
  let code = '';

  code += `<div class="content-container">`;
  code += `<div><h2>Nos EUA, o seguro viagem é obrigatório ou não?</h2></div>`;
  code += `<div class="content-two-columns">`;
  code += `<div>`;
  code += `<h3> que compõem o seguro viagem?</h3>`;
  code += `<div>${threeHundredWords}</div>`;
  code += `</div>`;
  code += `<div>`;
  code += `<h3>O que eu ganho comprando o seguro?</h3>`;
  code += `<div>${threeHundredWords}</div>`;
  code += `</div>`;
  code += `</div></div>`;

  return code;
}

function threeHundredWordsTwoColumnsLeftClear(){
  let code = '';

  code += `<div class="content-container">`;
  code += `<div><h2>Nos EUA, o seguro viagem é obrigatório ou não?</h2></div>`;
  code += `<div class="content-two-columns">`;
  code += `<div>`;
  code += `</div>`;
  code += `<div>`;
  code += `<h3>O que eu ganho comprando o seguro?</h3>`;
  code += `<div>${threeHundredWords}</div>`;
  code += `</div>`;
  code += `</div></div>`;

  return code;
}

function threeHundredWordsTwoColumnsRightClear(){
  let code = '';

  code += `<div class="content-container">`;
  code += `<div><h2>Nos EUA, o seguro viagem é obrigatório ou não?</h2></div>`;
  code += `<div class="content-two-columns">`;
  code += `<div>`;
  code += `<h3> que compõem o seguro viagem?</h3>`;
  code += `<div>${threeHundredWords}</div>`;
  code += `</div>`;
  code += `<div>`;
  code += `</div>`;
  code += `</div></div>`;

  return code;
}

const appendContent = {
  title,
  sixHundredWordsThreeColumns, sixHundredWordsTwoColumns,
  threeHundredWordsTwoColumnsLeftClear, threeHundredWordsTwoColumnsRightClear
}