<script>
  export let sd;
  export let nameItem;
  import { verifyKeysSD } from '../../../../lib/helper'; 
  import {json as jsonLivro} from '../../../schemaData/children/ReviewDataTypes/Livro.js';

  const bookFormatType = ['AudiobookFormat','EBook','GraphicNovel','Hardcover','Paperback'];
  if(sd["@type"] == "Book" && !Object.keys(sd).includes('sameAs')) sd = JSON.parse(JSON.stringify(jsonLivro));
  
  verifyKeysSD(jsonLivro, sd);
  $: { nameItem = `Livro: ${sd.name}`; };
</script>

<div>
  <div class="form-group row">
    <label for="book-name" class="col-sm-2 col-form-label">Nome do livro: </label>
    <div class="col-sm-10">
      <input id="book-name" name="book-name" class='form-control' bind:value={sd.name}/>
    </div>
  </div>
  <div class="form-group row">
    <label for="book-link" class="col-sm-2 col-form-label">Link para o livro: </label>
    <div class="col-sm-10">
      <input id="book-link" name="book-link" class='form-control' bind:value={sd.url}/>
    </div>
  </div>
  <div class="form-group row">
    <label for="book-capa" class="col-sm-2 col-form-label">Capa do livro: </label>
    <div class="col-sm-10">
      <input id="book-capa" name="book-capa" class='form-control' bind:value={sd.image}/>
    </div>
  </div>
  <div class="form-group row">
    <label for="book-author" class="col-sm-2 col-form-label">Autor do livro: </label>
    <div class="col-sm-10">
      <input id="book-author" name="book-author" class='form-control' bind:value={sd.author.name}/>
    </div>
  </div>
  <div class="form-group row">
    <label for="book-mais-sobre" class="col-sm-2 col-form-label">Mais sobre livro: </label>
    <div class="col-sm-10">
      <input id="book-mais-sobre" name="book-mais-sobre" class='form-control'  bind:value={sd.sameAs}/>
    </div>
  </div>
  <div class="form-group row">
    <label for="book-isbn" class="col-sm-2 col-form-label">ISBN: </label>
    <div class="col-sm-10">
      <input id="book-isbn" name="book-isbn" class='form-control'  bind:value={sd.isbn}/>
    </div>
  </div>
  <div class="form-group row">
    <label for="book-format" class="col-sm-2 col-form-label">Formato do livro </label>
    <div class="col-sm-10">
      <select id="book-format" name="book-format" class='form-select' bind:value={sd.bookFormat}>
        <option value="" disabled selected>Selecione o formato do livro</option>
        {#each bookFormatType as type}
          <option value="https://schema.org/{type}">{type}</option>
        {/each}
      </select>
    </div>
  </div>
  <div class="form-group row">
    <label for="book-language" class="col-sm-2 col-form-label">Linguagem: </label>
    <div class="col-sm-10">
      <input id="book-language" name="book-language" class='form-control' bind:value={sd.inLanguage}/>
    </div>
  </div>
</div>