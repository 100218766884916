export let json = {
  "@context": "https://schema.org",
  "@type": "Event",
  "name": "evento legal",
  "description": "Descrição",
  "image": "https://google.com/image.png",
  "startDate": "2021-10-05T19:00",
  "endDate": "2021-10-04T00:00",
  "eventStatus": "https://schema.org/EventMovedOnline",
  "eventAttendanceMode": "https://schema.org/OnlineEventAttendanceMode",
  "location": [{
    "@type": "VirtualLocation",
    "url": "https://stream.com/link"
  }],
  "offers": {
    "@type": "AggregateOffer",
    "lowPrice": "0.00",
    "highPrice": "0.00",
    "offerCount": "1938",
    "url": "https://www.example.com/event_offer/12345_201803180430",
    "priceCurrency": "USD",
    "price": "0.00",
    "availability": "",
    "validFrom": "2024-05-21T12:00"
  },
  "organizer": {
    "@type": "Organization",
    "name": "Story Time Virtual Events",
    "url": "https://torytimereadings.com.com"
  },
  "performer": {
    "@type": "Person",
    "name": "Ragatanga"
  }
};