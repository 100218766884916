export let json = {
  "@context": "https://schema.org",
  "@type": "LocalBusiness",
  "name": "Empresa Teste",
  "image": "https://teste.com/image.png",
  "@id": "https://teste.com",
  "url": "https://teste.com",
  "telephone": "84999999999",
  "priceRange": "$$",
  "address": {
    "@type": "PostalAddress",
    "streetAddress": "Rua teste",
    "addressRegion": "Estado teste",
    "addressLocality": "Cidade Teste",
    "postalCode": "59146140",
    "addressCountry": "BR"
  },
  "geo": {
    "@type": "GeoCoordinates",
    "latitude": -5.7841695,
    "longitude": -35.1999708
  },
  "openingHoursSpecification": [],
}
