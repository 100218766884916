export let json = {
  "@context": "https://schema.org/",
  "@type": "Review",
  "itemReviewed": {
    "@type": "Restaurant",
    "image": "http://www.example.com/seafood-restaurant.jpg",
    "name": "oi",
    "priceRange": "$$$",
    "telephone": "1234567",
    "address" :{
      "@type": "PostalAddress",
      "streetAddress": "123 William St",
      "addressLocality": "New York",
      "addressRegion": "NY",
      "postalCode": "10038",
      "addressCountry": "US"
    }
  },
  "reviewRating": {
    "@type": "Rating",
    "ratingValue": "4"
  },
  "name": "Titulo representativo do conteudo da review (Somente lido pelo google).",
  "author": {
    "@type": "Person",
    "name": "Real"
  },
  "reviewBody": "Descricao representativa ao conteudo da review. (É mostrado na review).",
  "publisher": {
    "@type": "Organization",
    "name": "Real Seguro Viagem"
  }
}