<script>
  export let sd;
  export let nameItem;
  import { verifyKeysSD, formatFulltDate, formatDate } from '../../../../lib/helper';
  import {json as howToStepJSON} from '../../../schemaData/children/HowToStep';
  import VideoObject from '../../VideoObject.svelte';
  import HowToStep from '../../children/HowToStep.svelte';
  import {json as jsonReceita} from '../../../schemaData/children/ReviewDataTypes/Receita.js';

  if(!Object.keys(sd).includes('recipeIngredient')) sd = JSON.parse(JSON.stringify(jsonReceita));
  verifyKeysSD(jsonReceita, sd);

  function appendStep(){ sd.step = [...sd.step, Object.assign({},howToStepJSON)] };
  function appendIngredient(){ sd.recipeIngredient = [...sd.recipeIngredient, ''] };
  function removeIngredient(idx){ sd.recipeIngredient = sd.recipeIngredient.filter( (e,i) => i !== idx ) }

  $: { nameItem = `Receita: ${sd.name}`; };
  let dates = { datePublished: formatDate(sd.datePublished) }
  
</script>

<div>
  <div class="form-group row">
    <label for="recipe-title" class="col-sm-2 col-form-label">Título: </label>
    <div class="col-sm-10">
      <input id="recipe-title" name="recipe-title" class='form-control' bind:value={sd.name}/>
    </div>
  </div>
  <div class="form-group row">
    <label for="recipe-autor" class="col-sm-2 col-form-label">Autor: </label>
    <div class="col-sm-10">
      <input id="recipe-autor" name="recipe-autor"  class='form-control' bind:value={sd.author.name}/>
    </div>
  </div>
  <div class="form-group row">
    <label for="recipe-image" class="col-sm-2 col-form-label">Imagem: </label>
    <div class="col-sm-10">
      <input id="recipe-image" name="recipe-image"  class='form-control' bind:value={sd.image}/>
    </div>
  </div>
  <div class="form-group row">
    <label for="recepe-published" class="col-sm-2 col-form-label">Publicado em: </label>
    <div class="col-sm-10">
      <input type="datetime-local" id="recepe-published" name="recepe-published" class='form-control' value="{dates.datePublished}" on:change={(e) => sd.datePublished = formatFulltDate(e.target.value)}/>
    </div>
  </div>
  <div class="form-group row">
    <label for="recipe-keyword" class="col-sm-2 col-form-label">Palavras Chave: </label>
    <div class="col-sm-10">
      <input id="recipe-keyword" name="recipe-keyword" class='form-control' bind:value={sd.keywords}/>
    </div>
  </div>
  <div class="form-group row">
    <label for="recipe-description" class="col-sm-2 col-form-label">Descrição: </label>
    <div class="col-sm-10">
      <input id="recipe-description" name="recipe-description" class='form-control' bind:value={sd.description}/>
    </div>
  </div>
  <div class="form-group row">
    <label for="recipe-steps" class="col-sm-2 col-form-label">Tempo de preparo: </label>
    <div class="col-sm-10">
      <input id="recipe-steps" name="recipe-steps" class='form-control' bind:value={sd.prepTime}/>
    </div>
  </div>
  <div class="form-group row">
    <label for="recipe-tempo-cozimento" class="col-sm-2 col-form-label">Tempo de cozimento </label>
    <div class="col-sm-10">
      <input id="recipe-tempo-cozimento" name="recipe-tempo-cozimento" class='form-control' bind:value={sd.cookTime}/>
    </div>
  </div>
  <div class="form-group row">
    <label for="recipe-porcoes" class="col-sm-2 col-form-label">Porções: </label>
    <div class="col-sm-10">
      <input id="recipe-porcoes" name="recipe-porcoes" class='form-control' bind:value={sd.recipeYield}/>
    </div>
  </div>
  <div class="form-group row">
    <label for="recipe-culianaria" class="col-sm-2 col-form-label">Culinária: </label>
    <div class="col-sm-10">
      <input id="recipe-culianaria" name="recipe-culianaria" class='form-control' bind:value={sd.recipeCuisine}/>
    </div>
  </div>
  <div class="form-group row">
    <label for="recipe-category" class="col-sm-2 col-form-label">Categoria: </label>
    <div class="col-sm-10">
      <input id="recipe-category" name="recipe-category" class='form-control' bind:value={sd.recipeCategory}/>
    </div>
  </div>
  <div class="form-group row">
    <label for="recipe-calories" class="col-sm-2 col-form-label">Calorias: </label>
    <div class="col-sm-10">
      <input id="recipe-calories" name="recipe-calories" class='form-control' bind:value={sd.nutrition.calories}/>
    </div>
  </div>
  <div class="form-group row">
    <label for="recipe-porcoes2" class="col-sm-2 col-form-label">Porção: </label>
    <div class="col-sm-10">
      <input id="recipe-porcoes2" name="recipe-porcoes2" class='form-control' bind:value={sd.nutrition.servingSize}/>
    </div>
  </div>
  <div class="form-group row">
    <label for="recipe-peso" class="col-sm-2 col-form-label">Peso: </label>
    <div class="col-sm-10">
      <input id="recipe-peso" name="recipe-peso" class='form-control' bind:value={sd.nutrition.fatContent}/>
    </div>
  </div>
  <br/><hr/><br/>

  <div class="form-group row">
    <div class="col-sm-2 col-form-label">Ingredientes: </div>
    <div class="col-sm-10">
      {#each sd.recipeIngredient as ingredient, i}
        <div class="relative row mb-2">
          <input class='form-control col' bind:value={ingredient}/>
          <button class="btn btn-danger remove col-sm-2" on:click|preventDefault={() => removeIngredient(i)}>Remover</button>
        </div>
      {/each}
      <div class="relative row mb-2">
        <button class="btn btn-primary col d-flex flex-row justify-content-center align-items-center" on:click|preventDefault={appendIngredient}><span class="more__icon">+</span><span>Adicionar ingrediente</span></button>
      </div>
    </div>
  </div>
  <br/><hr/><br/>
  <h6>Modo de preparo</h6>
  <div class="form-group row">
    <div class="col-sm-2 col-form-label">Passos: </div>
    <div class="col-sm-10">
      {#each sd.recipeInstructions as step, index }
        <HowToStep bind:step={step} {index} />
      {/each}
    </div>
  </div>
  <br/><hr/><br/>
  <h6>Avaliação</h6>
  <div class="form-group row">
    <label for="recipe-stars" class="col-sm-2 col-form-label">Estrelas (1 a 5): </label>
    <div class="col-sm-10">
      <input type='number' step="0.1" min="0" max="5" id="recipe-stars" name="recipe-stars" class='form-control' bind:value={sd.aggregateRating.ratingValue}/>
    </div>
  </div>
  <div class="form-group row">
    <label for="recipe-votes" class="col-sm-2 col-form-label">Quantidade de votos: </label>
    <div class="col-sm-10">
      <input id="recipe-votes" type='number' step="1" min="0" name="recipe-votes" class='form-control' bind:value={sd.aggregateRating.ratingCount}/>
    </div>
  </div>
  <br/><hr/><br/>
  <h6>Vídeo</h6>
  <VideoObject bind:sd={sd.video} />
</div>

<style>
  input.form-control.col{margin-right: 1rem;}
  .more__icon{ font-size: 1.5rem; transform: translate(-5px,-10px); height: 1rem; display: block;}
</style>