export let json = {
  "@context": "https://schema.org",
  "@type": "NewsArticle",
  "mainEntityOfPage": {
    "@type": "WebPage",
    "@id": "https://www.seguroviagem.srv.br/article"
  },
  "headline": "Titulo chamativo da noticia",
  "image": {
    "@type": "ImageObject",
    "url" : "https://example.com/photos/1x1/photo.jpg",
    "height": "1200",
    "width":  "1200"
  },
  "datePublished": "2021-02-05T08:00:00-03:00",
  "dateModified": "2021-02-05T09:20:00-03:00",
  "author": {
    "@type": "Person",
    "name": "Real",
    "email": "relacionamento@seguroviagem.srv.br",
    "telephone": "0800 606 6043",
    "jobTitle": "Redator",
    "url" : "https://www.seguroviagem.srv.br/"
  },
  "publisher": {
    "@type": "Organization",
    "name": "Real Seguro Viagem",
    "logo": {
      "@type": "ImageObject",
      "url": "https://a1.seguroviagem.srv.br/img/logo.svg",
      "height": "160",
      "width":  "48"
    }
  } 
};
