<script>
  import {json as howToDirectionJSON} from '../../schemaData/children/HowToDirection.js';
  import {json as howToTipJSON} from '../../schemaData/children/HowToTip.js';
  import HowToDirection from './HowToDirection.svelte';
  import HowToTip from './HowToTip.svelte';
  import ImageObject from './ImageObject.svelte';

  export let step, index;

  function prepareItemList(){
    if(typeof(step.itemListElement) == 'undefined') step.itemListElement = [];
  }
  function appendDirection(){ 
    prepareItemList();  
    step.itemListElement = [...step.itemListElement, Object.assign({},howToDirectionJSON)]; 
  };
  function appendTip(){ 
    prepareItemList();
    step.itemListElement = [...step.itemListElement, Object.assign({},howToTipJSON)]; 
  };
  function removeElement(idx){
    step.itemListElement = step.itemListElement.filter( (e,i) => i !== idx )
  }
</script>

<div class="directions">
  <h4>Passo {index+1} - {step.name}</h4>
  <div class="form-group row">
    <label for="nome-step" class="col-sm-2 col-form-label">Nome: </label>
    <div class="col-sm-10">
      <input id="nome-step" name="nome-step" class='form-control' bind:value={step.name}/>
    </div>
  </div>
  <div class="form-group row">
    <label for="descricao-step" class="col-sm-2 col-form-label">Descrição: </label>
    <div class="col-sm-10">
      <input id="descricao-step" name="descricao-step" class='form-control' bind:value={step.text}/>
    </div>
  </div>
  <div class="form-group row">
    <label for="url-step" class="col-sm-2 col-form-label">URL: </label>
    <div class="col-sm-10">
      <input id="url-step" name="url-step" class='form-control' bind:value={step.url}/>
    </div>
  </div>
  <div class="form-group row">
    <label for="image-step" class="col-sm-2 col-form-label">Image: </label>
    <div class="col-sm-10">
      {#if step.image.constructor == String}
        <input id="image-step" name="image-step" class='form-control' bind:value={step.image}/>
      {:else if step.image.constructor == Object}
        <ImageObject bind:image={step.image} />
      {/if}
    </div>
  </div>
  <div>
    {#if step.itemListElement}
      {#each step.itemListElement as obj, i}
        <div class="obj">
          {#if obj['@type'] == 'HowToDirection'}
            <HowToDirection bind:direction={obj} />
          {:else if obj['@type'] == 'HowToTip'}
            <HowToTip bind:tip={obj} />
          {/if}
          <button class="btn btn-danger col-sm-2 remove-element" on:click|preventDefault={() => removeElement(i)}>Remover</button>
        </div>
      {/each}
    {/if}
    <div class="form-group row mt-3">
      <label for="actions-step" class="col-sm-2 col-form-label"></label> 
      <div class="actions-i-d col-sm-10 row col-sm-10 d-flex align-items-md-center justify-content-md-between">
        <button class="btn btn-primary col-sm-5" on:click|preventDefault={appendDirection}>Adicionar instrução</button>
        <button class="btn btn-primary col-sm-5" on:click|preventDefault={appendTip}>Adicionar Dica</button>
      </div>
    </div>
  </div>
</div>

<style>
  .directions {border-left: 5px solid gray; border-radius:  5px; padding: 10px; margin:  5px;}
  .obj { position: relative; }
  .remove-element { position: absolute; top: 0;right: 0;}
  .actions-i-d{margin: 0;}
  .actions-i-d button.btn.btn-primary.col-sm-5{width: 49% !important;}
  .remove-element{ margin-top: 2px;}
</style>