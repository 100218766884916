<script>
  export let sd;
  export let nameItem;
  import { verifyKeysSD, formatDate } from '../../../../lib/helper'; 
  import Place from '../Place.svelte';
  import {json as jsonEvent} from '../../../schemaData/children/ReviewDataTypes/Evento.js';
  
  if(!Object.keys(sd).includes('eventStatus')) sd = JSON.parse(JSON.stringify(jsonEvent));
  
  verifyKeysSD(jsonEvent, sd);

  $: { nameItem = `Evento: ${sd.name}`; };

  let virtualLocation = {"@type": "VirtualLocation","url": "https://stream.com/linkghj"};
  let place = {"@type": "Place","name": "ola","address": {"@type": "PostalAddress","streetAddress": "teste","addressRegion": "regiao","addressLocality": "natal","postalCode": "000000000000000","addressCountry": "BR"}}
  let organization = { "@type": "Organization", "name": "Story Time Virtual Events", "url": "https://torytimereadings.com" };
  let person = { "@type": "Person", "name": "Dono do evento" };

  let dates = { startDate: formatDate(sd.startDate), endDate: formatDate(sd.endDate), validFrom: formatDate(sd.validFrom) }

  const set_location = () => {
    if(sd.eventAttendanceMode.includes('OnlineEventAttendanceMode')){
      sd.location = [virtualLocation];    
    }else if(sd.eventAttendanceMode.includes('OfflineEventAttendanceMode')){
      sd.location = [place]
    }else if(sd.eventAttendanceMode.includes('MixedEventAttendanceMode')){
      sd.location = [virtualLocation,place]
    }
  }
  
  const updateTypeOrganizer = () => {
    if(sd.organizer['@type'] == 'Organization') sd.organizer = organization;
    if(sd.organizer['@type'] == 'Person') sd.organizer = person;
  }
</script>

<div>
  <div class="form-group row">
    <label for="nome-do-evento-event" class="col-sm-2 col-form-label">Nome do Evento: </label>
    <div class="col-sm-10">
      <input id="nome-do-evento-event" name="nome-do-evento-event" class='form-control'  bind:value={sd.name}/>
    </div>
  </div>

  <div class="form-group row">
    <label for="imagem-do-evento" class="col-sm-2 col-form-label">Imagem do Evento: </label>
    <div class="col-sm-10">
      <input id="imagem-do-evento" name="imagem-do-evento" class='form-control'  bind:value={sd.image}/>
    </div>
  </div>

  <div class="form-group row">
    <label for="descricao-do-evento" class="col-sm-2 col-form-label">Descrição do evento: </label>
    <div class="col-sm-10">
      <input id="descricao-do-evento" name="descricao-do-evento" class='form-control'  bind:value={sd.description}/>
    </div>
  </div>
  <div class="form-group row">
    <label for="data-de-inicio-do-evento" class="col-sm-2 col-form-label">Data de Início: </label>
    <div class="col-sm-10">
      <input type="datetime-local" id="data-de-inicio-do-evento" value={dates.startDate} name="data-de-inicio-do-evento" class='form-control' on:change={(e) => sd.startDate = (new Date(e.target.value)).toISOString()}/>
    </div>
  </div>
  <div class="form-group row">
    <label for="data-de-termino-do-evento" class="col-sm-2 col-form-label">Data de Termino: </label>
    <div class="col-sm-10">
      <input type="datetime-local" min="{dates.startDate}" id="data-de-termino-do-evento" value={dates.endDate} name="data-de-termino-do-evento" class='form-control' on:change={(e) => sd.endDate = (new Date(e.target.value)).toISOString()}/>
    </div> 
  </div>
  <h6>Oferta</h6>
  <div class="form-group row">
    <label for="quantidade-disponivel-evento" class="col-sm-2 col-form-label">Quantidade: </label>
    <div class="col-sm-10">
      <input id="quantidade-disponivel-evento" name="quantidade-disponivel-evento" class='form-control' bind:value={sd.offers.offerCount}/>
      <small class="spam col-form-label">Quantidade disponível para acesso ao evento (quantidade de ingressos, etc..)</small>
    </div>
  </div>
  <div class="form-group row">
    <label for="url-do-evento" class="col-sm-2 col-form-label">Url: </label>
    <div class="col-sm-10">
      <input id="url-do-evento" name="url-do-evento" class='form-control' bind:value={sd.offers.url}/>
      <small class="spam col-form-label">Link para o evento ou informações sobre ele</small>
    </div>
  </div>
  <div class="form-group row">
    <label for="url-do-evento" class="col-sm-2 col-form-label">Moeda: </label>
    <div class="col-sm-10">
      <input id="url-do-evento" name="url-do-evento" class='form-control' bind:value={sd.offers.priceCurrency}/>
      <small class="spam col-form-label">Qual a moeda?</small>
    </div>
  </div>
  <div class="form-group row">
    <label for="url-do-evento" class="col-sm-2 col-form-label">Preço: </label>
    <div class="col-sm-10">
      <input id="url-do-evento" name="url-do-evento" type="number" min="0.0" step="0.01" class='form-control' bind:value={sd.offers.price}/>
      <small class="spam col-form-label">Preço médio ou aproximado, somente números com ponto.</small>
    </div>
  </div>
  <div class="form-group row">
    <label for="valor-minimo-estimado-do-evento" class="col-sm-2 col-form-label">Valor minimo: </label>
    <div class="col-sm-10">
      <input id="valor-minimo-estimado-do-evento" type="number" min="0.0" step="0.01" name="valor-minimo-estimado-do-evento" class='form-control'  bind:value={sd.offers.lowPrice}/>
      <small class="spam col-form-label">Valor estimado (minimo) para participação no evento.</small>
    </div>
  </div>
  <div class="form-group row">
    <label for="valor-maximo-estimado-do-evento" class="col-sm-2 col-form-label">Valor máximo: </label>
    <div class="col-sm-10">
      <input id="valor-maximo-estimado-do-evento" type="number" min="0.0" step="0.01" name="valor-maximo-estimado-do-evento" class='form-control'  bind:value={sd.offers.highPrice}/>
      <small class="spam col-form-label">Valor estimado (máximo) para participação no evento.</small>
    </div>
  </div>
  <div class="form-group row">
    <label for="disponibilidade-do-evento" class="col-sm-2 col-form-label">Disponibilidade: </label>
    <div class="col-sm-10">
      <select id="disponibilidade-do-evento" name="disponibilidade-do-evento" class='form-select' bind:value={sd.offers.availability}>
        <option value="" disabled selected>selecione a disponibilidade</option>
        <option value="https://schema.org/BackOrder">Pedido pendente</option>
        <option value="https://schema.org/Discontinued">Interrompido</option>
        <option value="https://schema.org/InStock">Em estoque</option>
        <option value="https://schema.org/InStoreOnly">Presencialmente</option>
        <option value="https://schema.org/LimitedAvailability">Disponibilidade limitada</option>
        <option value="https://schema.org/OnlineOnly">Somente online</option>
        <option value="https://schema.org/OutOfStock">Fora de estoque</option>
        <option value="https://schema.org/PreOrder">Pedido antecipado</option>
        <option value="https://schema.org/PreSale">Pré-Venda</option>
        <option value="https://schema.org/SoldOut">Esgotado</option>
      </select>
    </div>
  </div>
  <div class="form-group row">
    <label for="url-do-evento" class="col-sm-2 col-form-label">Válido a partir de: </label>
    <div class="col-sm-10">
      <input type="datetime-local" id="data-de-inicio-do-evento" value={dates.validFrom} name="data-de-validade-do-evento" class='form-control' on:change={(e) => sd.offers.validFrom = (new Date(e.target.value)).toISOString()}/>
      <small class="spam col-form-label">Data em que o item se torna válido.</small>
    </div>
  </div>

  <div class="form-group row">
    <label for="status-do-evento" class="col-sm-2 col-form-label">Status do Evento: </label>
    <div class="col-sm-10">
      <select id="status-do-evento" name="status-do-evento" class='form-select' bind:value={sd.eventStatus}>
        <option value="" disabled selected>Selecione o modo de participação</option>
        <option value="https://schema.org/EventScheduled">Agendado</option>
        <option value="https://schema.org/EventPostponed">Postergado</option>
        <option value="https://schema.org/EventCancelled">Cancelado</option>
        <option value="https://schema.org/EventMovedOnline">Movido Online</option>
      </select>
    </div>
  </div>

  <div class="form-group row">
    <label for="responsavel-pelo-evento" class="col-sm-2 col-form-label">Responsavel: </label>
    <div class="col-sm-10">
      <select id="responsavel-pelo-evento" name="responsavel-pelo-evento" class='form-select' bind:value={sd.organizer['@type']} on:blur={updateTypeOrganizer}>
        <option value="" disabled selected>Selecione o modo tipo de responsavel</option>
        <option value="Organization">Organização</option>
        <option value="Person">Pessoa</option>
      </select>
    </div>
  </div>

  {#if sd.organizer['@type'] == 'Organization'}
    <h6>Organização</h6>
    <div class="form-group row">
      <label for="nome-da-organizacao-do-evento" class="col-sm-2 col-form-label">Nome: </label>
      <div class="col-sm-10">
        <input id="nome-da-organizacao-do-evento" name="nome-da-organizacao-do-evento" class='form-control'  bind:value={sd.organizer.name}/>
      </div>
    </div>
    <div class="form-group row">
      <label for="url-da-organizacao-do-evento" class="col-sm-2 col-form-label">Url: </label>
      <div class="col-sm-10">
        <input id="url-da-organizacao-do-evento" name="url-da-organizacao-do-evento" class='form-control'  bind:value={sd.organizer.url}/>
      </div>
    </div>
  {:else}
    <h6>Pessoa fisica</h6>
    <div class="form-group row">
      <label for="nome-do-organizador-do-evento" class="col-sm-2 col-form-label">Nome: </label>
      <div class="col-sm-10">
        <input id="nome-do-organizador-do-evento" name="nome-do-organizador-do-evento" class='form-control'  bind:value={sd.organizer.name}/>
      </div>
    </div>
  {/if}

  <div class="form-group row">
    <label for="forma-de-participacao-do-evento" class="col-sm-2 col-form-label">Forma de participação: </label>
    <div class="col-sm-10">
      <select id="forma-de-participacao-do-evento" name="forma-de-participacao-do-evento" class='form-select' bind:value={sd.eventAttendanceMode} on:blur={set_location}>
        <option value="" disabled selected>Selecione o modo de participacao</option>
        <option value="https://schema.org/OnlineEventAttendanceMode">Online</option>
        <option value="https://schema.org/OfflineEventAttendanceMode">Offline</option>
        <option value="https://schema.org/MixedEventAttendanceMode">Mixed</option>
      </select>
    </div>
  </div>

  {#each sd.location as location}
    {#if typeof(location.url) !== 'undefined'}
      <h6>Online</h6>
      <div class="form-group row">
        <label for="url-do-eventp-online" class="col-sm-2 col-form-label">URL do Evento online: </label>
        <div class="col-sm-10">
          <input id="url-do-eventp-online" name="url-do-eventp-online" class='form-control'  bind:value={location.url}/>
        </div>
      </div>
    {/if}
    {#if typeof(location.address) !== 'undefined'}
      <Place bind:location={location} />
    {/if}
  {/each}

  <div class="form-group row">
    <label for="atracao-do-evento" class="col-sm-2 col-form-label">Atração: </label>
    <div class="col-sm-10">
      <select id="atracao-do-evento" name="atracao-do-evento" class='form-select' bind:value={sd.performer["@type"]}>
        <option value="" disabled selected>Selecione o tipo de apresentacao</option>
        <option value="Person">Pessoa</option>
        <option value="PerformingGroup">Grupo de Atuação</option>
        <option value="MusicGroup">Banda Musica</option>
        <option value="DanceGroup">Grupo de Dança</option>
        <option value="TheaterGroup">Grupo de Teatro</option>
      </select>
    </div>
  </div>
  <div class="form-group row">
    <label for="nome-da-atracao" class="col-sm-2 col-form-label">Nome do Artista: </label>
    <div class="col-sm-10">
      <input id="nome-da-atracao" name="nome-da-atracao" class='form-control' bind:value={sd.performer.name}/>
    </div>
  </div>
</div>
