<script>
  export let tip;
</script>

<div class="form-group row">
  <label for="dica-how-to-tip" class="col-sm-2 col-form-label">Dica: </label>
  <div class="col-sm-8">
    <input id="dica-how-to-tip" name="dica-how-to-tip" class='form-control' bind:value={tip.text}/>
  </div>
</div>
