export let json = {
  "@context": "https://schema.org",
  "@type": "VideoObject",
  "name": "20 Min Aerobic Workout Reduction",
  "description": "Get your exercise in, from home. Watch this quick 20 minute workout video.",
  "thumbnailUrl": [
    "https://example.com/photos/1x1/photo.jpg",
    "https://example.com/photos/4x3/photo.jpg",
    "https://example.com/photos/16x9/photo.jpg"
   ],
  "uploadDate": "2020-03-31T08:00:00+08:00",
  "duration": "PT20M46S",
  "contentUrl": "https://www.googlebotclass.com/video/123/20-min-workout.mp4",
  "embedUrl": "https://www.googlebotclass.com/embed/123",
  "interactionStatistic": {
    "@type": "InteractionCounter",
    "interactionType": { "@type": "http://schema.org/WatchAction" },
    "userInteractionCount": 5647018
  },
  "regionsAllowed": "US"
};