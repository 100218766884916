export let json = {
  "@context": "https://schema.org",
  "@type": "FAQPage",
  "mainEntity": [
      {
      "@type": "Question",
      "name": "Como adquirir o seguro viagem?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Basta preencher o destino e o período da viagem, selecionar o plano desejado, preencher os dados do(s) viajante(s) e realizar o pagamento em cartão ou boleto."
      }
    },
    {
      "@type": "Question",
      "name": "Qual a diferença entre seguro saúde e seugro viagem?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text":"O seguro saúde possui apenas a cobertura médica, como por exemplo: consultas, internações. Já o seguro viagem possui outras coberturas, como: extravio e localização de bagagem, indenização em caso de morte, fiança, auxílio financeiro, entres outras. Além disso, o seguro viagem não cobre atendimentos médicos de rotina, apenas doenças e acidentes."}
    }
  ]
}

